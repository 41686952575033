<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0">
    <PageHeader
      header-text="Alerts"
      regular-button-text="New Alert"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for alerts"
      :current-page-number="alertsPageNumber"
      :current-page-size="alertsPageSize"
      :count-total="alertsTotal"
      :page-sizes="[25, 50, 100]"
      :init-search-string="searchString"
      @next-page="getAlertsNextPage"
      @prev-page="getAlertsPrevPage"
      @change-page-size="alertsChangePageSize"
      @search="(searchString) => onSearch(searchString)"
    />

    <AlertTable
      :alerts="alerts"
      @action="handleAction($event)"
      @image-preview="openPreviewImage($event)"
    />

    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="editedAlert = {}">
      <AlertEdit
        v-if="dialog"
        :alert-item="editedAlert"
        :available-tags="tagNames"
        @save="saveAlert"
        @dismiss="dialog = false; editedAlert = {}"
      />
    </v-dialog>

    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import AlertTable from "@/views/Channels/components/AlertTable";
import AlertEdit from "@/views/Channels/components/AlertEdit";
import { hasError } from "@/mixins/has_error";

export default {
  name: "AlertView",
  metaInfo: {
    title: 'Alert Creatives'
  },
  components: {
    AlertEdit,
    AlertTable,
    SearchFieldRowWithPagination,
    PageHeader,
    ImagePreviewDialog,
  },
  mixins: [ hasError ],
  data() {
    return {
      editedAlert: {},
      dialog: false,
      alerts: [],
      alertsTotal: 0,
      alertsPageNumber: 1,
      alertsPageSize: 25,
      searchString: '',
      tags: [],
      tagNames: [],
      previewImageDialog: false,
      previewImageUrl: null,
      previewFullImageUrl: null,
      previewImageName: null,
    };
  },
  created() {
    this.getAvailableAlerts(this.$route.query.keyword);
    this.getAlertTags();
  },
  methods: {
    openPreviewImage({ name, image }) {
      this.previewImageUrl = image.medium || image.large || image.full || image.small;
      this.previewFullImageUrl = image.full || image.large || image.medium || image.small;
      this.previewImageName = name;
      this.previewImageDialog = !!this.previewImageUrl;
    },

    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.alerts.put_resource(action.item.id, {
          ...action,
          isArchived: true,
        });
        await this.getAvailableAlerts();
      } else if (action.event.value === "edit") {
        this.editedAlert = {...action.item}
        await this.getTagsOfAlert();
        this.dialog = true;
      }
    },
    async onSearch(searchString) {
      this.searchString = searchString;
      await this.getAvailableAlerts(searchString);
    },
    async saveAlert(alert) {
      // pre-processing - parse tags
      alert.tagItems = alert.tagItems.map((tag) => {
        const tagItem = this.tags.find((tagInstance) => tagInstance.name.toLowerCase() === tag.toLowerCase());

        return tagItem
          ? { id: tagItem.id } // use existing tag ID
          : { name: tag }; // create new tab
      });

      if (alert.id) {
        await this.$rest.alerts.put_resource(alert.id, alert);
        this.$notifier.success("Successfully edited the alert");
      } else {
        await this.$rest.alerts.post_resource(alert);
        this.$notifier.success("Successfully added the alert");
      }
      this.editedAlert = {};
      this.dialog = false;
      await this.getAvailableAlerts()
      await this.getAlertTags();
    },
    async alertsChangePageSize(size) {
      this.alertsPageSize = size;
      this.alertsPageNumber = 1;
      await this.getAvailableAlerts();
    },
    async getAlertsNextPage() {
      this.alertsPageNumber += 1;
      await this.getAvailableAlerts();
    },
    async getAlertsPrevPage() {
      this.alertsPageNumber -= 1;
      await this.getAvailableAlerts();
    },
    async getAvailableAlerts(searchString) {
      if (searchString) {
        this.searchString = searchString;
      } else {
        this.searchString = "";
      }
      const resp = await this.$rest.alerts.get_collection({
        limit: this.alertsPageSize,
        page: this.alertsPageNumber,
        name: this.searchString,
        sort: ['createdAt:desc'],
        isArchived: false,
        groups: ["tag_creative:get"],
      });
      this.alerts = resp.data.items;
      this.alertsTotal = resp.data.totalCount;
    },
    async getAlertTags() {
      const resp = await this.$rest.tags.getCollection({
        fields: ['id', 'name'],
        ignorePagination: true,
      }).catch(this.showGenericError);

      if (!resp.data) {
        return;
      }

      this.tags = [...resp.data.items];
      this.tagNames = this.tags.map((item) => item.name);
    },
    async getTagsOfAlert() {
      const response = await this.$rest.alerts.getTags(this.editedAlert.id)
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.editedAlert.tags = response.data.items.map((tagInfo) => tagInfo.tag.name);
    },
  },
};
</script>
